import React from 'react'
import { Link } from 'react-router-dom'

function cardServiceFrontComponent(props) {
    return (
        <div className="cardServiceFrontComponent">
            <img src={props.image}/>
            <div className="w-100 text-center">
                <h3 className="__title">{props.title}</h3>
            </div>
            <p className="__description">{props.description}</p>
            {/*<div className="__footer">*/}
            {/*    <Link className="__link" to={props.link}> Ver más </Link>*/}
            {/*</div>*/}
            
        </div>
    );
}

export default cardServiceFrontComponent