import { React, Fragment, useState } from 'react'
import logoblack from '../assets/img/logoblack-heydru.svg';
import bienesdarI from '../assets/img/bienesdarI-heydru.jpg';
import bienesdarII from '../assets/img/bienesdarII-heydru.jpg';
import bienesdar from '../assets/img/bienesdar-heydru.jpg';
import peid from '../assets/img/peid-heydru.jpg';
import peidI from '../assets/img/peidI-heydru.jpg';
import peidII from '../assets/img/peidII-heydru.jpg';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import ItemsCarousel from 'react-items-carousel';

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 1, // optional, default to 1.
        partialVisibilityGutter: 40
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 1, // optional, default to 1.
        partialVisibilityGutter: 30
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
        partialVisibilityGutter: 30
    }
};
const CustomButtonGroup = ({ next, previous, goToSlide, carouselState }) => {
    const { totalItems, currentSlide } = carouselState;
    return (
        <div className="custom-button-group">
            <div className="right">
            <button className="btnArrow" onClick={() => previous()}>{'<'}</button>
            </div>
            <div className=" left">
            <button className="btnArrow"  onClick={() => next()}>{'>'}</button>
            </div>
        </div>
    );
};

const Portfolio = ({deviceType}) => {
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const chevronWidth = 40;

    return (
        <Fragment>
            <div className="portfolioComponent" id="portfolio">
                <div className="container">
                    <div className="row" >
                        <div className="col-lg-12">
                            <div className=" d-flex flex-row justify-content-center" >
                                <img className="logoblack me-4" src={logoblack} alt="logoblack" width="34px" height="35px" />
                                <h1>Nuestro portafolio</h1>
                            </div>
                        </div>
                    </div>

                    {/*<div className="portfolioRow" style={{ padding: `0 ${chevronWidth}px` }}>*/}
                    <div className="portfolioRow">
                        {/*<ItemsCarousel*/}
                        {/*    gutter={20}*/}
                        {/*    requestToChangeActive={setActiveItemIndex}*/}
                        {/*    activeItemIndex={activeItemIndex}*/}
                        {/*    numberOfCards={3}*/}
                        {/*    slidesToScroll={1}*/}
                        {/*    showSlither={true}*/}
                        {/*    infiniteLoop={true} */}
                        {/*    leftChevron={<button className="btnArrow">{'<'}</button>}*/}
                        {/*    rightChevron={<button className="btnArrow">{'>'}</button>}*/}
                        {/*    outsideChevron*/}
                        {/*    chevronWidth={chevronWidth}*/}
                        {/*>*/}
                        <Carousel
                            arrows={false}
                            responsive={responsive}
                            ssr={true} // means to render carousel on server-side.
                            infinite={true}
                            deviceType={deviceType}
                            itemClass="image-item"
                            containerClass="container-padding-bottom"
                            customButtonGroup={<CustomButtonGroup />}
                        >

                            <div className="itemPortfolio">
                                <img className="bienesdarI" src={bienesdarI} alt="bienesdarI" />
                            </div>
                            <div className="itemPortfolio">
                                <img className="bienesdar" src={bienesdar} alt="bienesdar" />
                            </div>
                            <div className="itemPortfolio">
                                <img className="bienesdarII" src={bienesdarII} alt="bienesdarII" />
                            </div>
                            <div className="itemPortfolio">
                                <img className="peidI" src={peidI} alt="peidI" />
                            </div>
                            <div className="itemPortfolio">
                                <img className="peid" src={peid} alt="peid" />
                            </div>
                            <div className="itemPortfolio">
                                <img className="peidII" src={peidII} alt="peidII" />
                            </div>
                        </Carousel>
                        {/*</ItemsCarousel>*/}
                    </div>
                </div>
            </div>

        </Fragment>
    );
}
export default Portfolio