import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Landing from '../pages/Landing';

const appRoute =() =>{
    return (
        <Router>
            <div>
                <Switch>
                    <Route exact path="/" component={Landing} />
                </Switch>
            </div>
        </Router>
    )
}

export default appRoute;