import { Link } from "react-router-dom";
import Nav from 'react-bootstrap/Nav'

function buttonComponent(props){
    return (
        <div className="buttonComponent">
            <img src={props.img} width="22" height="23" />
            <Nav.Link href="https://meetings.hubspot.com/heydru/conversemos" target="_blank"> {props.text} </Nav.Link>
        </div>
    );
}

export default buttonComponent