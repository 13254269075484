import Nav from 'react-bootstrap/Nav'
function buttonSecondaryComponent(props){
    return (
        <div className={props.styleButtonSecondary}>
            <img src={props.img} width="24" height="24" />
            {/* <p>{props.text}</p> */}
            <Nav.Link href={props.link} target="_blank"> {props.text} </Nav.Link>     
        </div>
    );
}

export default buttonSecondaryComponent