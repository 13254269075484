import { React, Fragment } from 'react'
import laptop from '../assets/img/laptop-heydru.svg'
import ButtonComponent from './elements/buttonComponent';
import calendarSvg from '../assets/img/calendar-heydru.svg'

const Hero = () => {

    return (
        <Fragment>
  
            <div className="container">
                <div className="hero">
                    <div className="content-hero col-lg-6 d-flex flex-column align-items-start text-start">
                        <h3 className="spaceb18 sub-title">¡Somos Heydru!</h3>
                        <h1 className="spaceb18 title">Tus aliados en el camino digital</h1>
                        <p className="spaceb18">Desarrollamos productos digitales con dedicación, calidad, rapidez;
                            superando las expectativas del cliente.</p>
                            <ButtonComponent img={calendarSvg} text="Agenda una cita" />

                    </div>
                    <div className="col-lg-6">
                        <img className="laptop" src={laptop} alt="laptop" />
                    </div>
                </div>
            </div>
        </Fragment>
    );

}
export default Hero