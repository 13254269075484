import React, { Fragment, useState } from 'react'
import Nav from 'react-bootstrap/Nav';
import logoblack from '../assets/img/logoblack-heydru.svg';
import alicia from '../assets/img/team-alicia-heydru.png';
import katty from '../assets/img/team-kathi-heydru.png';
import alejandra from '../assets/img/team-alejandra.png';
import eduardo from '../assets/img/team-edu-heydru.png';
import gabriel from '../assets/img/team-gabriel-heydru.png';
import rasmir from '../assets/img/team-rasmir-heydru.png';
import miguel from '../assets/img/team-miguel-heydru.png';
import TeamMemberComponent from './elements/teamMemberComponent';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 1, // optional, default to 1.
        partialVisibilityGutter: 40
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 1, // optional, default to 1.
        partialVisibilityGutter: 30
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1,// optional, default to 1.
        partialVisibilityGutter: 30
    }
};

const CustomButtonGroup = ({ next, previous, goToSlide, carouselState }) => {
    const { totalItems, currentSlide } = carouselState;
    return (
        <div className="custom-button-group">
            <div className="b_right_work">
                <button className="btnArrow" onClick={() => previous()}>{'<'}</button>
            </div>
            <div className="b_left_work">
                <button className="btnArrow"  onClick={() => next()}>{'>'}</button>
            </div>
        </div>
    );
};

const TeamWork = ({deviceType}) => {
    const [show, setShow] = useState(true)
    return (
        <Fragment>

            <div className="teamworkComponent" id="teamwork">

                <div className="titleTeamwork">
                    <img className="logoblack me-4" src={logoblack} alt="logoblack" width="34px" height="35px" />
                    <h1>Equipo de trabajo</h1>
                </div>

                <div className="container">
                    <div className="gridTeams">
                        <div className="carrouselTeam" >

                            <Carousel
                                arrows={false}
                                responsive={responsive}
                                ssr={true} // means to render carousel on server-side.
                                infinite={true}
                                deviceType={deviceType}
                                itemClass="image-item"
                                containerClass="container-padding-bottom"
                                customButtonGroup={<CustomButtonGroup />}
                            >
                                <TeamMemberComponent
                                    avatar={eduardo}
                                    name="Eduardo Telaya"
                                    role="CEO / Fundador"
                                    ilike="Me gusta practicar baile fitness"
                                    linkedin="https://www.linkedin.com/in/edutrul/"
                                />

                                <TeamMemberComponent
                                    avatar={alejandra}
                                    name="Alejandra Amezaga"
                                    role="GERENTE GENERAL"
                                    ilike="Tengo 4 gatos"
                                    linkedin="https://www.linkedin.com/in/alejandraamezaga/"
                                />

                                <TeamMemberComponent
                                    avatar={alicia}
                                    name="Alicia Peralta"
                                    role="Analista / Desarrolladora"
                                    ilike="Me gusta BTS y leer novelas"
                                    linkedin="https://www.linkedin.com/in/alicia-peralta-702157235/"
                                />

                                <TeamMemberComponent
                                    avatar={katty}
                                    name="Katherine Vito"
                                    role="Desarrolladora"
                                    ilike="Me gusta leer"
                                    linkedin="https://www.linkedin.com/in/katherine-vito-laura-0980a8258/"
                                />

                                <TeamMemberComponent
                                    avatar={gabriel}
                                    name="Gabriel Correa"
                                    role="Asesor de ventas"
                                    ilike="Me gusta leer"
                                    linkedin="https://www.linkedin.com/in/gabriel-correa-a70b7a263"
                                />

                                <TeamMemberComponent
                                    avatar={rasmir}
                                    name="Rasmir Gongora"
                                    role="Director audiovisual "
                                    ilike="Me gusta los videojuegos"
                                    linkedin="https://www.linkedin.com/in/rasmir-gongora-figueroa-182789224/"
                                />

                                <TeamMemberComponent
                                    avatar={miguel}
                                    name="Miguel Rodríguez"
                                    role="Director de RRHH "
                                    ilike="Me encanta la Psicología y el Surf"
                                    linkedin="https://www.linkedin.com/in/miguel-rodr%C3%ADguez-escalante-a3aab6248/"
                                />

                            </Carousel>
                        </div>

                        <div className="teamItems">
                            <TeamMemberComponent
                                avatar={eduardo}
                                name="Eduardo Telaya"
                                role="CEO / Fundador"
                                ilike="Me gusta practicar baile fitness"
                                linkedin="https://www.linkedin.com/in/edutrul/"
                            />

                            <TeamMemberComponent
                                avatar={alejandra}
                                name="Alejandra Amezaga"
                                role="GERENTE GENERAL"
                                ilike="Tengo 4 gatos"
                                linkedin="https://www.linkedin.com/in/alejandraamezaga/"
                            />

                        </div>
                        {
                            show ? (<div></div>) : (<div className="viewall2" >
                                    <div>
                                        <TeamMemberComponent
                                            avatar={alicia}
                                            name="Alicia Peralta"
                                            role="Analista / Desarrolladora"
                                            ilike="Amo a Jungkook y me gusta ver animes"
                                            linkedin="https://www.linkedin.com/in/alicia-peralta-702157235/"
                                        />

                                        <TeamMemberComponent
                                            avatar={gabriel}
                                            name="Gabriel Correa"
                                            role="Asesor de ventas"
                                            ilike="Me gusta leer"
                                            linkedin="https://www.linkedin.com/in/gabriel-correa-a70b7a263"
                                        />

                                        <TeamMemberComponent
                                            avatar={miguel}
                                            name="Miguel Rodríguez"
                                            role="Director de RRHH "
                                            ilike="Me encanta la Psicología y el Surf"
                                            linkedin="https://www.linkedin.com/in/miguel-rodr%C3%ADguez-escalante-a3aab6248/"
                                        />
                                    </div>
                                    <div>
                                        <TeamMemberComponent
                                            avatar={katty}
                                            name="Katherine Vito"
                                            role="Desarrolladora"
                                            ilike="Me gusta leer y escuchar música"
                                            linkedin="https://www.linkedin.com/in/katherine-vito-laura-0980a8258/"
                                        />

                                        <TeamMemberComponent
                                            avatar={rasmir}
                                            name="Rasmir Gongora"
                                            role="Director audiovisual "
                                            ilike="Me gusta los videojuegos"
                                            linkedin="https://www.linkedin.com/in/rasmir-gongora-figueroa-182789224/"
                                        />
                                    </div>
                                </div>
                            )
                        }

                        <Nav.Link className="linkViewMore" onClick={() => { setShow(!show) }}hidden={show ?  false : true} >Ver más</Nav.Link>
                        <Nav.Link className="linkViewMore" onClick={() => { setShow(!show) }} hidden={!show ?  false : true}>Ver menos</Nav.Link>

                    </div>
                </div>
            </div>
        </Fragment>

    );
}
export default TeamWork