import { React, Fragment, useState } from "react"
import logoblack from '../assets/img/logoblack-heydru.svg'
import personbw from '../assets/img/personbw-heydru.svg'
import CardFeedbackComponent from './elements/cardFeedbackComponent'
import RightArrow from '../assets/img/right-arrow-heydru.svg'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import ItemsCarousel from 'react-items-carousel';

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2,
        slidesToSlide: 1, // optional, default to 1.
        partialVisibilityGutter: 40
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 1, // optional, default to 1.
        partialVisibilityGutter: 30
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
        partialVisibilityGutter: 30
    }
};

const CustomButtonGroup = ({ next, previous, goToSlide, carouselState }) => {
    const { totalItems, currentSlide } = carouselState;
    return (
        <div className="custom-button-group">
            <div className="feedback_right comment_right">
                <button className="btnArrow" onClick={() => previous()}>{'<'}</button>
            </div>
            <div className="feedback_left">
                <button className="btnArrow"  onClick={() => next()}>{'>'}</button>
            </div>
        </div>
    );
};

const Feedback = ({deviceType}) => {
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const chevronWidth = 40;
    return (
        
        <Fragment>
            <div className="feedbackComponents">
                <div className="container">
                <div className="titleFeedback">
                    <img className="logoblack me-4" src={logoblack} alt="logoblack" width="34px" height="35px" />
                    <h1>Comentarios</h1>
                </div>

                {/*<div className="feedbackRow" style={{ padding: `0 ${chevronWidth}px` }}>*/}
                <div className="feedbackRow comment_margin">
                    {/*<ItemsCarousel*/}
                    {/*    requestToChangeActive={setActiveItemIndex}*/}
                    {/*    activeItemIndex={activeItemIndex}*/}
                    {/*    numberOfCards={2}*/}
                    {/*    showSlither={true}*/}
                    {/*    infiniteLoop={true}*/}
                    {/*    gutter={3}*/}
                    {/*    leftChevron={<button className="btnArrow">{'<'}</button>}*/}
                    {/*    rightChevron={<button className="btnArrow">{'>'}</button>}*/}
                    {/*    outsideChevron*/}
                    {/*    chevronWidth={chevronWidth}*/}
                    {/*>*/}
                    {/*    <CardFeedbackComponent*/}
                    {/*        avatar={personbw}*/}
                    {/*        feedback="“Supo adaptar nuestra iniciativa en cuanto al nombre y logo”"*/}
                    {/*        author="José Herrera"*/}
                    {/*    />*/}
                    {/*    <CardFeedbackComponent*/}
                    {/*        avatar={personbw}*/}
                    {/*        feedback="“Hizo uso de herramientas interactivas y la forma como lo desarrolló fue de manera super ágil y con resultados”"*/}
                    {/*        author="Jose Cabanillas"*/}
                    {/*    />*/}
                        {/*<CardFeedbackComponent*/}
                        {/*    avatar={personbw}*/}
                        {/*    feedback="“Agilidad y  resultado ante todo”"*/}
                        {/*    author="Miriam Herrera"*/}
                        {/*/>*/}
                    {/*</ItemsCarousel>*/}
                    <Carousel
                        arrows={false}
                        swipeable
                        responsive={responsive}
                        ssr={true} // means to render carousel on server-side.
                        infinite={true}
                        deviceType={deviceType}
                        itemClass="image-item"
                        containerClass="container-padding-bottom"
                        customButtonGroup={<CustomButtonGroup />}
                    >
                        <CardFeedbackComponent
                            avatar={personbw}
                            feedback="“Supo adaptar nuestra iniciativa en cuanto al nombre y logo”"
                            author="José Herrera"
                        />
                        <CardFeedbackComponent
                            avatar={personbw}
                            feedback="“Hizo uso de herramientas interactivas y la forma como lo desarrolló fue de manera super ágil y con resultados”"
                            author="Jose Cabanillas"
                         />
                        <CardFeedbackComponent
                            avatar={personbw}
                            feedback="“Agilidad y  resultado ante todo”"
                            author="Miriam Herrera"
                        />
                    </Carousel>

                </div>
                </div>

            </div>

        </Fragment>
    )
}

export default Feedback