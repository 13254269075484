import { React, Fragment} from 'react'
import NavComponent from './navComponent';

 const Header = () => {

    return(
        <Fragment>
            <NavComponent />
        </Fragment>
    );

}

export default Header