import React, { Fragment, useState } from 'react'
import logoblack from '../assets/img/logoblack-heydru.svg';
import devywork from '../assets/img/devywork.png';
import heydru from '../assets/img/heydru.png';
import heydruplay from '../assets/img/heydruplay.png';
import logo_eduardo from '../assets/img/logo_eduardo.png';
import milly_dance from '../assets/img/milly_dance.png';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import BrandsLogosComponent from "./elements/brandsLogosComponent";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 1, // optional, default to 1.
        partialVisibilityGutter: 40
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 1, // optional, default to 1.
        partialVisibilityGutter: 30
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1,// optional, default to 1.
        partialVisibilityGutter: 30
    }
};

const CustomButtonGroup = ({ next, previous, goToSlide, carouselState }) => {
    const { totalItems, currentSlide } = carouselState;
    return (
        <div className="custom-button-group">
            <div className="right_brands">
                <button className="btnArrow" onClick={() => previous()}>{'<'}</button>
            </div>
            <div className="left_brands">
                <button className="btnArrow"  onClick={() => next()}>{'>'}</button>
            </div>
        </div>
    );
};

const Brands = ({deviceType}) => {
    return (
        <Fragment>

            <div className="brandsComponent " id="brands">
                <div className="container" >
                    <div className="headerBrands  d-flex flex-column align-items-center">
                <div className="titleBrands d-flex align-items-center ">
                    <img className="logoblack me-4" src={logoblack} alt="logoblack" width="34px" height="35px" />
                    <h1>Marcas</h1>
                </div>
                    </div>
                </div>

                <div className="container">

                    <Carousel
                        arrows={false}
                        responsive={responsive}
                        ssr={true} // means to render carousel on server-side.
                        infinite={true}
                        deviceType={deviceType}
                        itemClass="image-item"
                        containerClass="container-padding-bottom"
                        customButtonGroup={<CustomButtonGroup />}
                    >

                    <BrandsLogosComponent
                        avatar={heydru}
                    />

                    <BrandsLogosComponent
                        avatar={devywork}
                    />

                    <BrandsLogosComponent
                        avatar={heydruplay}
                    />

                    <BrandsLogosComponent
                        avatar={logo_eduardo}
                    />

                    <BrandsLogosComponent
                        avatar={milly_dance}
                    />

                    </Carousel>
                </div>
            </div>
        </Fragment>

    );
}
export default Brands
