import { React, Fragment, useState } from 'react'
import logoblack from '../assets/img/logoblack-heydru.svg';
import palta from '../assets/img/palta-heydru.svg';
import person from '../assets/img/person-heydru.svg';
import micro from '../assets/img/micro-heydru.svg'
import ChipComponent from './elements/chipComponent';
import video from '../assets/img/video-heydru.svg';
import articulo from '../assets/img/articulo-heydru.svg';
import podcast from '../assets/img/podcast-heydru.svg';
import ButtonSecondaryComponent from './elements/buttonSecondaryComponent';
import ItemsCarousel from 'react-items-carousel';
import eduardoLaptop from '../assets/img/eduardo-laptop-heydru.jpg';
import equipos from '../assets/img/equipos.jpeg';
import hackathon from '../assets/img/hackathon.png';
import convenio from '../assets/img/convenio.png';
import chambea from '../assets/img/chambea.png';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 1,// optional, default to 1.
        partialVisibilityGutter: 40
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 1, // optional, default to 1.
        partialVisibilityGutter: 30
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
        partialVisibilityGutter: 30
    }
};

const CustomButtonGroup = ({ next, previous, goToSlide, carouselState }) => {
    const { totalItems, currentSlide } = carouselState;
    return (
        <div className="custom-button-group">
            <div className="b_right_article">
                <button className="btnArrow" onClick={() => previous()}>{'<'}</button>
            </div>
            <div className="b_left_article">
                <button className="btnArrow"  onClick={() => next()}>{'>'}</button>
            </div>
        </div>
    );
};

const Article = ({deviceType}) => {
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const chevronWidth = 40;
    
    return (
        <Fragment>
            <div className="articleComponent">
                <div className="container" >
                    <div className="headerArticle d-flex flex-column align-items-center">
                        <div className=" tituloArticle d-flex align-items-center ">
                            <img className="logoblack me-4" src={logoblack} alt="logoblack" width="34px" height="35px" />
                            <h1>Contenido reciente</h1>
                        </div>
                        <p>Compartimos experiencia y buenas prácticas a través de nuestros contenidos y día a día de nuestro trabajo.</p>
                    </div>
                </div>
                    {/*<div className="feedbackRow " style={{ padding: `0 ${chevronWidth}px` }}>*/}
                <div className="container article_recient">
                    <div className="feedbackRow " >

                        {/*<ItemsCarousel*/}
                        {/*    requestToChangeActive={setActiveItemIndex}*/}
                        {/*    activeItemIndex={activeItemIndex}*/}
                        {/*    numberOfCards={3}*/}
                        {/*    slidesToScroll={1}*/}
                        {/*    showSlither={true}*/}
                        {/*    infiniteLoop={true} */}
                        {/*    leftChevron={<button className="btnArrow">{'<'}</button>}*/}
                        {/*    rightChevron={<button className="btnArrow">{'>'}</button>}*/}
                        {/*    outsideChevron*/}
                        {/*    chevronWidth={chevronWidth}*/}
                        {/*>*/}
                        <Carousel
                            arrows={false}
                            responsive={responsive}
                            ssr={true} // means to render carousel on server-side.
                            infinite={true}
                            deviceType={deviceType}
                            itemClass="image-item"
                            containerClass="container-padding-bottom"
                            customButtonGroup={<CustomButtonGroup />}
                        >

                            <div className="cardArticle">
                                <img className="eduardoLaptop margin_image" src={equipos} alt="equipos" />
                                <div className="contentCardArticle">
                                    <ChipComponent
                                        styleChip="videoChipComponent"
                                        text="Video"
                                    />
                                    <h2>Conoce nuestros equipos audiovisuales!</h2>

                                    <ButtonSecondaryComponent
                                        styleButtonSecondary="videoButtonComponent"
                                        img={video}
                                        text="Ver Videos"
                                        link="https://www.youtube.com/watch?v=KnbkOVJ0avI"
                                    />
                                </div>
                            </div>
                            <div className="cardArticle">
                                <img className="hackathon margin_image" src={convenio} alt="equipos" />
                                <div className="contentCardArticle">
                                    <ChipComponent
                                        styleChip="videoChipComponent"
                                        text="Video"
                                    />
                                    <h2>Convenio interinstitucional | ONGD | heydru!</h2>

                                    <ButtonSecondaryComponent
                                        styleButtonSecondary="videoButtonComponent"
                                        img={video}
                                        text="Ver Videos"
                                        link="https://www.youtube.com/watch?v=25bTzJQ1bTM"
                                    />
                                </div>
                            </div>
                            <div className="cardArticle">
                                <img className="hackathon margin_image" src={chambea} alt="chambea" />
                                <div className="contentCardArticle">
                                    <ChipComponent
                                        styleChip="videoChipComponent"
                                        text="Video"
                                    />
                                    <h2>Webinar: ¿Cómo crear sitios webs desde cero?</h2>

                                    <ButtonSecondaryComponent
                                        styleButtonSecondary="videoButtonComponent"
                                        img={video}
                                        text="Ver Videos"
                                        link="https://www.youtube.com/watch?v=4AiYJY0lBro"
                                    />
                                </div>
                            </div>
                            <div className="cardArticle">
                                <img className="hackathon margin_image" src={hackathon} alt="hackathon" />
                                <div className="contentCardArticle">
                                    <ChipComponent
                                        styleChip="videoChipComponent"
                                        text="Video"
                                    />
                                    <h2>¿Qué ventajas tiene participar en una hackathon? </h2>

                                    <ButtonSecondaryComponent
                                        styleButtonSecondary="videoButtonComponent"
                                        img={video}
                                        text="Ver Videos"
                                        link="https://www.youtube.com/watch?v=xqA4eKfgZSk&t=1318s"
                                    />
                                </div>
                            </div>
                            <div className="cardArticle">
                                <img className="person margin_image" src={person} alt="person" />
                                <div className="contentCardArticle">
                                    <ChipComponent
                                        styleChip="blogChipComponent"
                                        text="Blog"
                                    />
                                    <h2>¿Por qué ir a una hackathon?</h2>

                                    <ButtonSecondaryComponent
                                        styleButtonSecondary="articuloButtonComponent"
                                        img={articulo}
                                        text="Leer Artículo"
                                        link="https://medium.com/@hi_55751/qu%C3%A9-ventajas-tiene-participar-en-un-hackathon-7fd18d5e16ae"
                                    />
                                </div>
                            </div>
                            <div className="cardArticle">
                                <img className="micro margin_image" src={micro} alt="micro" />
                                <div className="contentCardArticle">
                                    <ChipComponent
                                        styleChip="podcastChipComponent"
                                        text="Podcast"
                                    />
                                    <h2>Emprendimiento Caso de éxito - PEID</h2>
                                    <ButtonSecondaryComponent
                                        styleButtonSecondary="podcastButtonComponent"
                                        img={podcast}
                                        text="Escuchar Podcast"
                                        link="https://open.spotify.com/user/h1r5hummylbcu7u60hk0wl2of?si=U09xyOSnSFuD26nUkvRJPw&utm_source=copy-link&dl_branch=1&nd=1"
                                    />
                                </div>
                            </div>
                            {/* <div className="cardArticle">
                                <img className="palta" src={palta} alt="palta" />
                                <div className="contentCardArticle">
                                    <ChipComponent
                                        styleChip="videoChipComponent"
                                        text="Video"
                                    />
                                    <h2>Un día en heydru!</h2>

                                    <ButtonSecondaryComponent
                                        styleButtonSecondary="videoButtonComponent"
                                        img={video}
                                        text="Ver Videos"
                                        link="https://www.youtube.com/watch?v=Ccl_V5StX14"
                                    />
                                </div>
                            </div> */}
                            {/* <div className="cardArticle">
                                <img className="person" src={person} alt="person" />
                                <div className="contentCardArticle">
                                    <ChipComponent
                                        styleChip="blogChipComponent"
                                        text="Blog"
                                    />
                                    <h2>Un blog del último dinosaurio</h2>

                                    <ButtonSecondaryComponent
                                        styleButtonSecondary="articuloButtonComponent"
                                        img={articulo}
                                        text="Leer Artículo"
                                        link="https://medium.com/@hi_55751"
                                    />
                                </div>
                            </div> */}
                            {/* <div className="cardArticle">
                                <img className="micro" src={micro} alt="micro" />
                                <div className="contentCardArticle">
                                    <ChipComponent
                                        styleChip="podcastChipComponent"
                                        text="Podcast"
                                    />
                                    <h2>El camino antes de la extinción</h2>
                                    <ButtonSecondaryComponent
                                        styleButtonSecondary="podcastButtonComponent"
                                        img={podcast}
                                        text="Escuchar Podcast"
                                        link="https://open.spotify.com/user/h1r5hummylbcu7u60hk0wl2of?si=U09xyOSnSFuD26nUkvRJPw&utm_source=copy-link&dl_branch=1&nd=1"
                                    />
                                </div>
                            </div> */}
                        </Carousel>
                        {/*</ItemsCarousel>*/}
                    </div>
                </div>
            </div>
        </Fragment >
    );
}
export default Article