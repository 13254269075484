import React from 'react'
import { Link } from 'react-router-dom'

function cardFeedbackComponent(props) {
    return (
        <div className="cardFeedbackComponent comment">
            <div className="d-flex flex-column align-items-center justify-content-center">
                <img src={props.avatar} alt="avatar"/>

            </div>

            <div className="contentCardFeedbackComponent d-flex flex-column col-text justify-content-center">
                <p className="__feedback">{props.feedback}</p>
                <h3 className="__author">{props.author}</h3>
            </div>

        </div>
    );
}

export default cardFeedbackComponent