import { React, Fragment } from 'react'
import logowhite from '../assets/img/logowhite-heydru.svg';
import blocks from '../assets/img/blocks-heydru.svg';
import hear from '../assets/img/hear-heydru.svg'
import cloud from '../assets/img/cloud-heydru.svg';
import space from '../assets/img/space-heydru.svg';
import arrow from '../assets/img/arrow-heydru.svg';
import strategy from '../assets/img/strategy-heydru.svg';
import strategyborder from '../assets/img/strategyborder-heydru.svg'
import arrowsm from '../assets/img/arrowsm-heydru.svg';
import CardMetodolgyComponent from './elements/cardMetodolgyComponent'
import CardThreeColComponent from './elements/cardThreeColComponent';

const Methodology = () => {

    return (
        <Fragment>
            <div className="sectionMetodology">
                <div className="headerMetodology d-flex flex-column align-items-center">
                    <div className="d-flex align-items-center">
                        <img className="logowhite me-4" src={logowhite} alt="logowhite" />
                        <h1>¿Cómo trabajamos?</h1>
                    </div>
                    <p>Aquí te plasmamos los 3 simples pasos que seguiremos para el éxito, posicionamiento y lanzamiento de tu proyecto.</p>
                </div>

                <div className="contentMetodology container">
                    <div className="d-flex stepContent flex-row align-items-center mb-4">
                        <div className="col-lg-5 cardOne pt-84 d-flex flex-column align-items-center">
                            <CardMetodolgyComponent
                                title="¡Te escuchamos!"
                                description="Nuestros clientes son parte escencial de nuestro proceso de trabajo. ¡Escuchamos tu idea y lo hacemos realidad!"
                                link="#"
                            />
                        </div>
                        <div className="col-lg-2 stepOne">
                            <div>
                                <h2>Paso</h2>
                                <h1>01</h1>
                            </div>
                            <div>
                                <img className="space" src={space} alt="space" />
                            </div>

                        </div>
                        <div className="col-lg-5 pt-84 d-flex flex-column align-items-center">
                            <img className="hear" src={hear} alt="hear" />
                        </div>
                    </div>
                    <div className="d-flex stepContent2 flex-row align-items-center mb-4">
                        <div className="col-lg-5 pt-84 d-flex flex-column align-items-center">
                            <img className="blocks" src={blocks} alt="blocks" />
                        </div>
                        <div className="col-lg-2 stepTwo">
                            <div>
                                <h2>Paso</h2>
                                <h1>02</h1>
                            </div>
                            <div>
                                <img className="space" src={space} alt="space" />
                            </div>
                        </div>
                        <div className="col-lg-5 cardTwo pt-84 d-flex flex-column align-items-center">
                            <CardMetodolgyComponent
                                title="ARMAMOS TU PROPUESTA GANADORA"
                                description="El equipo Heydru! moldeará tus ideas y generará un proyecto funcional que cumpla todas las expectativas."
                            />
                        </div>

                    </div>
                    <div className="d-flex stepContent flex-row align-items-center mb-4">
                        <div className="col-lg-5 cardThree pt-84 d-flex flex-column  align-items-center">
                            <CardMetodolgyComponent
                                title="¡Desarrollamos!"
                                description="Damos inicio con el desarrollo de tu proyecto. De la manera más ágil y eficiente posible. "
                            />
                        </div>
                        <div className="col-lg-2 stepThree">
                            <div>
                                <h2>Paso</h2>
                                <h1>03</h1>
                            </div>
                            <div>
                                <img className="arrow" src={arrow} alt="arrow" />
                            </div>
                        </div>
                        <div className="col-lg-5 pt-84 d-flex flex-column align-items-center">
                            <img className="cloud" src={cloud} alt="cloud" />
                        </div>
                    </div>
                    <div className="textYellow col-lg-12 mt-4">
                        <h3>Te mostramos</h3>
                        <img className="logowhite me-4" src={logowhite} alt="logowhite" />
                        <h2>Nuestra metodología</h2>
                    </div>
                    <div className="d-flex flex-row justify-content-center mt-5">
                        <CardThreeColComponent
                            icon={strategy}
                            iconborder={strategyborder}
                            title="Estrategia"
                            description="Aquí construímos la identidad de tu marca, mediante reuniones colaborativas."
                        // icon2={arrowsm}
                        />
                    </div>
                </div>
            </div>

        </Fragment>
    );

}

export default Methodology