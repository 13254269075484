import { React } from 'react'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import logo from '../assets/img/logo-heydru.svg';
import ButtonComponent from './elements/buttonComponent';
import calendarSvg from '../assets/img/calendar-heydru.svg'

const NavComponent = () => {

    return (
        <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
            <Container>
                <Navbar.Brand href="#home">
                    <img className="logo" src={logo} alt="logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav d-flex flex-row justify-content-around">
                    <Nav className="m-auto itemMenu">
                        <Nav.Link className="me-item" href="#service"><span className="test">Servicios</span></Nav.Link>
                        <Nav.Link className="me-item" href="#portfolio"><span className="test">Portafolio</span></Nav.Link>
                        <Nav.Link className="me-item" href="#teamwork"><span className="test">Equipo</span></Nav.Link>
                        <Nav.Link className="me-item" href="#brands"><span className="test">Marcas</span></Nav.Link>
                    </Nav>
                    <Nav>
                        <Nav.Link href="#deets">
                            <ButtonComponent img={calendarSvg} text="Agenda una cita" />
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );

}
export default NavComponent