import { React, Fragment, useState } from "react";
import desarrolloweb from "../assets/img/desarrolloweb-heydru.svg";
import marketing from "../assets/img/marketing-heydru.svg";
import logoblue from '../assets/img/logoblue-heydru.svg';
// import CardServiceBackComponent from './elements/cardServiceBackComponent'
import CardServiceFrontComponent from './elements/cardServiceFrontComponent'

const Service = () => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const chevronWidth = 40;

  return (
    <Fragment>
      <div className="sectionService" id="service">
        <div className="container">
          <div className="header-section d-flex flex-row justify-content-center align-items-center" >
            <img className="logoblue me-4" src={logoblue} alt="logoblue" width="34px" height="35px" />
            <h1>Nuestro Servicios</h1>
          </div>
          <div className="serviceContentDesk">

            <CardServiceFrontComponent image={marketing}
              title="MARKETING Y ESTRATEGIA DIGITAL"
              description="Elaboramos una estrategia para posicionar correctamente el producto y/o marca en medios digitales."
              // link="/"
            />
            <CardServiceFrontComponent image={desarrolloweb}
              title="PRODUCCIÓN AUDIOVISUAL"
              description="Creamos contenido audiovisual que potencia tu producto."
              // link="/"
            />
            <CardServiceFrontComponent image={desarrolloweb}
              title="Outsourcing"
              description="Ponemos a disposición el mejor talento en tech."
              // link="/"
            />

          </div>
          
        </div>
      </div>

    </Fragment>
  );
}
export default Service
