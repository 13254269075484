import { React, Fragment } from "react"
import calendar from '../assets/img/calendar-heydru.svg'
import ButtonComponent from "./elements/buttonComponent"

const Contact = () => {
    return (
        <Fragment>
            <div>
                <div className="contactComponent" >
                    <div className="col-lg-12">
                        <h1>¡Trabajemos tu siguiente proyecto!</h1>
                        <div className="d-flex flex-row justify-content-center">
                            <ButtonComponent
                                img={calendar}
                                text="Agendar una cita"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Contact