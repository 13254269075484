import React from 'react'
import { Link } from 'react-router-dom'

function cardMetodolgyComponent(props) {
    return (
        <div className="cardMetodolgyComponent">

            <div className="w-100">
                <h3 className="__title">{props.title}</h3>
            </div>
            <p className="__description">{props.description}</p>

        </div>
    );
}

export default cardMetodolgyComponent