import React from 'react'
import { Link } from 'react-router-dom'

function cardThreeColComponent(props) {
    return (
        <div className="cardThreeColComponent">
            <div className="__icon">
                <img src={props.icon}/>
            </div>
            <div className="__iconborder">
            <img src={props.iconborder}/>
            </div>
            <div>
                <h3 className="__title">{props.title}</h3>
                <p className="__description">{props.description}</p>
            </div>
            {/* <div className="__icon2">
                <img src={props.icon2} alt="arrow-next"  />
            </div> */}
        </div>
    );
}

export default cardThreeColComponent