import linkedin from "../../assets/img/linkedin-heydru.png";
import {React} from "react";
import twitter from "../../assets/img/twitter-heydru.png";

function teamMemberComponent(props) {
    return (
        <div className="teamMemberComponent"  style={{paddingRight: `80px`}}>
            <img className="avatar" src={props.avatar} width="212" height="212" />
            <img className="avatarCrazy" src={props.avatarCrazy} width="22" height="23" />
            <div className="contentTeamMemberComponent">
                <h2>{props.name}</h2>
                <h3>{props.role}</h3>
                <p>{props.ilike}</p>
                <div className="position_linkedin">
                    <a href={props.linkedin} target="_blank" >
                    <img src={linkedin}   width="40" height="40" alt="linkedin" />
                    </a>
                </div>
            </div>
        </div>
    );
}

export default teamMemberComponent