import { React, Fragment } from "react"
import facebook from '../assets/img/facebook-heydru.png'
import twitter from '../assets/img/twitter-heydru.png'
import instagram from '../assets/img/instagram-heydru.png'
import linkedin from '../assets/img/linkedin-heydru.png'
import whatsapp from '../assets/img/whatsapp-heydru.png'
import logo from '../assets/img/logo-heydru.svg'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
/*import politicasprivacidad from '../assets/pdf/politicas-de-privacidad.pdf'*/

const Footer = () => {
    return (
        <Fragment>

            <div className="footerComponent container">
                <div className="row footerMenu">
                    {/*<div className="col colFooterA">*/}
                    {/*    <h3>Ayuda</h3>*/}
                    {/*    <p>Atención al Cliente</p>*/}
                    {/*</div>*/}
                    <div className="col colFooterI">
                        <h3>Información legal</h3>

                        <Nav.Link href={'#'} target="_blank">
                        <p>Políticas de privacidad</p>
                        </Nav.Link>
                        <Nav.Link href="#" target="_blank">
                        <p>Nuestros términos y condiciones</p>
                        </Nav.Link>

                    </div>
                    <div className="col colFooteS">
                        <h3>Servicios</h3>
                        <p>Naming + Branding</p>
                        <p>Diseño y Desarrollo web</p>
                        <p>Marketing y Estrategía Digital</p>

                    </div>
                    <div className="col colFooter">
                        <h3>Contáctanos por:</h3>
                          
                        <div className="rowSocials">
                        <Nav.Link class="facebook" href="https://www.facebook.com/heydru.oficial" target="_blank">
                            <img src={facebook} width="40" height="40" alt="facebook" />
                        </Nav.Link>
                        <Nav.Link class="twitter" href="https://twitter.com/heydru_oficial" target="_blank">
                            <img src={twitter} width="40" height="40" alt="twitter" />
                        </Nav.Link>
                        <Nav.Link class="instagram" href="https://www.instagram.com/heydru.oficial/" target="_blank">
                            <img src={instagram} width="40" height="40" alt="instagram" />
                        </Nav.Link>
                        <Nav.Link class="linkedin" href="https://www.linkedin.com/company/heydru" target="_blank">
                            <img src={linkedin} width="40" height="40" alt="linkedin" />
                        </Nav.Link>
                        <Nav.Link class="whatsapp" target="_blank" href="https://bit.ly/whatsapp-ponte-en-contacto">
                            <img src={whatsapp} width="40" height="40" alt="whatsapp" />
                        </Nav.Link>
                        
                        </div>

                    </div>
                </div>
                <hr />
                <div className="row footerheydru">
                    <img className="logo" src={logo} alt="logo" width="138px" height="36px" />
                    <p padding="top">©heydru! todos los derechos reservados</p>
                </div>

            </div>

        </Fragment>
    )
}
export default Footer