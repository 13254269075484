import {React} from "react";

function brandsLogosComponent(props) {
    return (
        <div className="teamMemberComponent"  style={{paddingRight: `80px`}}>
            <img className="avatar" src={props.avatar} width="212" height="212" />
        </div>
    );
}

export default brandsLogosComponent