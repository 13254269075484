import Header from '../components/header'
import Hero from '../components/hero'
import Service from '../components/service'
import Methodology from '../components/methodology'
import Portfolio from '../components/portfolio'
import Article from '../components/article'
import Teamwork from '../components/teamwork'
import Feedback from '../components/feedback'
import Contact from '../components/contact'
import Footer from '../components/footer'
import Brands from '../components/marcas'

function Landing(){
    return (
        <div className="Landing d-flex flex-column">
            <Header />
            <Hero />
            <Service />
            <Methodology />
            <Portfolio />
            <Article />
            <Teamwork />
            <Brands />
            <Contact />
            <Feedback />
            <Footer /> 
        </div>
    )

}
export default Landing